<template>
  <main class="kb-main" id="kb-facility">
    <!-- main-header -->
    <header class="kb-header" id="kb-header">
      <div v-if="!isSearchAreaShow" class="header-component">
        <!-- header-title -->
        <div class="header-column header-title">공지사항</div>
        <!-- //header-title -->
        <!-- header-util -->
        <div class="header-column header-util right">
          <div class="util util-search">
            <a href="javascript:" @click="isSearchAreaShow = true" class="util-actions util-actions-search">
              <i class="icon-search"></i>
            </a>
          </div>
        </div>
        <!-- //header-util -->
      </div>

      <!-- 검색영역  -->
      <div v-else class="header-component">
        <!-- header-util -->
        <div class="header-column header-util left">
          <div class="util util-back">
            <a href="javascript:" @click="isSearchAreaShow = false" class="util-actions util-actions-back">
              <i class="icon-history-back"></i>
            </a>
          </div>
          <div class="util util-search-input">
            <input type="text" v-model="search.ntcTitle" placeholder="공지사항 검색">
          </div>
        </div>
        <!-- //header-util -->
        <!-- header-util -->
        <div class="header-column header-util right">
          <div class="util util-search">
            <a href="javascript:" @click="clickSearch" class="util-actions util-actions-search">
              <i class="icon-search"></i>
            </a>
          </div>
        </div>
        <!-- //header-util -->
      </div>
    </header>
    <!-- //main-header -->

    <!-- main-content -->
    <div class="main-content main-component">
      <div v-if="items.length === 0" class="search-result">
        <!-- result-empty -->
        <div class="result-empty">
          <img src="@/assets/lxp/mobile/images/_common/img_empty.png">
          <p class="text">검색결과가 없어요</p>
        </div>
        <!-- //result-empty -->
      </div>

      <div v-else class="notice-list-container">
        <div class="list-top status">
          <div class="top-column">
            <p v-if="isSearchAreaShow" class="text text-muted">검색결과 {{ paging.totalCount }}건</p>
            <p v-else class="text text-muted">전체 {{ paging.totalCount }}건</p>
          </div>
        </div>
        <div class="board-list-container">
          <ul class="board-list">
            <li v-for="(item, idx) in items" class="board-list-item" :key="idx">
              <router-link :to="{path: '/daily/notice/'+item.trnctNtcSn}" class="board-link"></router-link>
              <div class="list-content">
                <div class="list-content-title">
                  <div v-if="item.topFixYn === 'Y'" class="prefix">
                    <em class="text text-gold">필독</em>
                  </div>
                  <h5 class="title">
                    <span class="text text-truncate">{{item.ntcTitle}}</span>
                    <i v-if="item.newNotice === 'Y'" class="icon-new ms-1"></i>
                  </h5>
                </div>
                <div class="list-meta-content">
                  <span class="text">전체관리자</span><span class="text">{{ getDateFormat('yyyy-MM-dd', item.regDt)}} 작성</span><span class="text">{{item.inqCnt}}
                      조회</span>
                </div>
                <div  v-if="item.ntcFileAtchYn === 'Y'" class="board-column column-file"><a href="javascript:" class="file-link"><i class="icon-file"></i></a></div>
              </div>
            </li>
          </ul>

          <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>

        </div>
      </div>

    </div>
    <!-- //main-content -->
  </main>
</template>

<script>

import {computed, onBeforeMount, onMounted, reactive, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {
  getCdNm,
  getItems,
  getPaging,
  initPaging,
  initProgressBar,
  lengthCheck,
  setParamsByQueryString,
  timestampToDateFormat,
} from '@/assets/js/util';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {ACT_GET_TRNCT_NOTICE_LIST} from "@/store/modules/trnct/trnct";

export default {
  name: 'TrnctMobileNotice',
  components: {CommonPaginationFront},
  setup(){
    const isCategoryActive = ref(false);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const paging = ref(initPaging(route));
    const items = ref([{}]);
    const search = reactive({
      ntcTitle:'',
      ntcCateCdDcd: ''
    });
    const isSearchAreaShow = ref(false);

    const ntcCateCdDcd = computed(() => store.state.code.codes.filter(code => code.cd.substring(4) !== '000' && code.cd.indexOf('2022') === 0));
    const nowDt = computed(() => timestampToDateFormat(new Date(), 'yyyy-MM-dd'));

    const cateNm = computed(() => {
      if (search.ntcCateCdDcd && search.ntcCateCdDcd.length > 0) return getCdNm(store, search.ntcCateCdDcd);
      return '카테고리';
    });

    watch(() => isSearchAreaShow.value, () => {
      if(isSearchAreaShow.value){
        items.value = [];
        paging.value.totalCount = 0;
      }else{
        search.ntcTitle = '';
        if(Object.keys(route.query).length === 0) getHelpNoticeList();
        else pagingFunc();
      }
    })

    const getHelpNoticeList = () => {
      store.dispatch(`trnct/${ACT_GET_TRNCT_NOTICE_LIST}`, {
        ntcTitle: search.ntcTitle,
        pageNo: paging.value.pageNo,
        pageSize: 2
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res);
          paging.value = getPaging(res);

          items.value.map((x) => {
            let diff = new Date(nowDt.value) - new Date(timestampToDateFormat(x.regDt, 'yyyy-MM-dd'));
            let month = new Date(diff).getMonth() > 0 ? new Date(diff).getMonth() * 30 : 0;
            let date = new Date(diff).getDate();
            if(month + date <= 7) x.newNotice = 'Y';
            else x.newNotice = 'N';
          })

        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      })
    };

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      if(search.ntcTitle) query.ntcTitle = search.ntcTitle;
      if(search.ntcCateCdDcd) query.ntcCateCdDcd = search.ntcCateCdDcd;
      router.push({query: query});
    };

    const clickSearch = () => {
      pagingFunc(1, 2);
    };

    const selectCate = (dcd) => {
      if (dcd === '2022001') dcd =  '';
      search.ntcCateCdDcd = dcd;
      pagingFunc(1, 2);
    };

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    watch(() => route.query, () => {
      setParamsByQueryString(route, 'TrnctNotice', paging, search, true);
      getHelpNoticeList();
      initProgressBar();
    });

    onBeforeMount(() => {
      setParamsByQueryString(route, 'TrnctNotice', paging, search, true);
    });

    onMounted(() => {
      getHelpNoticeList();
    });

    return{
      isCategoryActive,
      items,
      paging,
      search,

      pagingFunc,
      clickSearch,
      getDateFormat,

      ntcCateCdDcd,
      selectCate,
      cateNm,
      isSearchAreaShow
    }

  }
}
</script>
